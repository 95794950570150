import React from 'react';

function Checkmark() {
    return (
        <svg height="100px" viewBox="0 0 512 512" width="100px" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m504.5 256c0 137.242188-111.257812 248.5-248.5 248.5s-248.5-111.257812-248.5-248.5 111.257812-248.5 248.5-248.5 248.5 111.257812 248.5 248.5zm0 0"
                fill="#60daa8"/>
            <path
                d="m238.082031 423.925781c20.503907-19.4375 29.59375-46.28125 29.59375-46.28125l-33.046875-28.058593s-40.609375-78.015626-40.359375-77.820313c-90.667969-105.4375-22.222656-248.394531-21.445312-250.003906-96.328125 34.214843-165.324219 126.175781-165.324219 234.238281 0 127.902344 96.648438 233.238281 220.886719 246.984375-16.1875-24.519531-12.496094-58.027344 9.695312-79.058594zm0 0"
                fill="#00ce8e"/>
            <path
                d="m240.808594 389.574219c-7.742188 0-15.527344-2.476563-22.085938-7.539063l-117.144531-90.363281c-15.828125-12.207031-18.761719-34.933594-6.550781-50.761719 12.207031-15.824218 34.933594-18.757812 50.757812-6.550781l90.632813 69.914063 125.046875-138.390626c13.398437-14.828124 36.285156-15.988281 51.117187-2.585937 14.828125 13.398437 15.988281 36.28125 2.589844 51.113281l-147.496094 163.234375c-7.113281 7.875-16.953125 11.929688-26.867187 11.929688zm0 0"
                fill="#fffcdc"/>
            <path
                d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.640625-74.980469-181.019531 0-58.257812 19.066406-113.144531 55.136719-158.730469 2.570312-3.246093 7.285156-3.796875 10.535156-1.226562 3.25 2.570312 3.796875 7.285156 1.226563 10.535156-33.953126 42.910156-51.898438 94.578125-51.898438 149.421875 0 64.375 25.066406 124.894531 70.585938 170.414062 45.519531 45.519532 106.039062 70.585938 170.414062 70.585938s124.894531-25.066406 170.414062-70.585938c45.519532-45.519531 70.585938-106.039062 70.585938-170.414062s-25.066406-124.894531-70.585938-170.414062c-45.519531-45.519532-106.039062-70.585938-170.414062-70.585938-63.414062 0-123.285156 24.429688-168.589844 68.785156-2.957031 2.894532-7.707031 2.847656-10.605468-.113281-2.898438-2.960937-2.847657-7.707031.109374-10.605469 48.125-47.117187 111.726563-73.066406 179.085938-73.066406 68.378906 0 132.667969 26.628906 181.019531 74.980469 48.351563 48.351562 74.980469 112.640625 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0 0"/>
            <path
                d="m240.8125 397.074219c-9.734375 0-18.957031-3.148438-26.671875-9.097657l-117.144531-90.363281c-9.238282-7.128906-15.152344-17.429687-16.644532-29.003906-1.496093-11.574219 1.609376-23.035156 8.734376-32.277344 7.128906-9.238281 17.429687-15.148437 29.003906-16.644531 11.574218-1.492188 23.039062 1.609375 32.277344 8.738281l85.136718 65.675781 120.398438-133.242187c7.820312-8.660156 18.550781-13.753906 30.203125-14.34375 11.65625-.589844 22.84375 3.390625 31.503906 11.214844s13.753906 18.554687 14.34375 30.210937c.589844 11.652344-3.394531 22.839844-11.21875 31.5l-19.984375 22.117188c-2.777344 3.074218-7.519531 3.3125-10.59375.535156-3.070312-2.777344-3.3125-7.519531-.535156-10.589844l19.984375-22.117187c10.605469-11.738281 9.761719-30-2.054688-40.523438-16.730469-14.90625-35.382812-3.636719-40.519531 2.050781l-125.046875 138.390626c-2.625 2.90625-7.042969 3.300781-10.144531.910156l-90.636719-69.914063c-6.0625-4.679687-27.496094-11.683593-40.238281 5.191407-12.742188 16.878906-.875 35.5625 5.191406 40.242187l117.144531 90.363281c5.066407 3.910156 11.121094 5.972656 17.511719 5.972656 8.105469 0 15.871094-3.445312 21.300781-9.453124l106.839844-118.242188c2.777344-3.074219 7.519531-3.3125 10.59375-.535156 3.070313 2.773437 3.3125 7.519531.535156 10.589844l-106.839843 118.246093c-8.269532 9.148438-20.089844 14.398438-32.429688 14.398438zm0 0"/>
        </svg>
    );
}

export default Checkmark;
