import React from 'react';

function FaceMask() {
    return (
        <svg id="Capa_1" enableBackground="new 0 0 512 512" height="30px" viewBox="0 0 512 512" width="30px"
             xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="m82.581 313.806h-16.516c-36.428 0-66.065-29.637-66.065-66.064v-148.645c0-18.218 14.815-33.032 33.032-33.032s33.032 14.815 33.032 33.032v90.839c0 4.565-3.694 8.258-8.258 8.258s-8.258-3.694-8.258-8.258v-90.839c0-9.105-7.411-16.516-16.516-16.516s-16.516 7.411-16.516 16.516v148.645c0 27.323 22.226 49.548 49.548 49.548h16.516c4.565 0 8.258 3.694 8.258 8.258.001 4.565-3.693 8.258-8.257 8.258z"
                    fill="#f5ebcd"/>
                <path
                    d="m429.419 313.806h16.516c36.427 0 66.065-29.637 66.065-66.065v-148.644c0-18.218-14.815-33.032-33.032-33.032s-33.032 14.815-33.032 33.032v90.839c0 4.565 3.694 8.258 8.258 8.258s8.258-3.694 8.258-8.258v-90.839c0-9.105 7.411-16.516 16.516-16.516s16.516 7.411 16.516 16.516v148.645c0 27.323-22.226 49.548-49.548 49.548h-16.516c-4.565 0-8.258 3.694-8.258 8.258-.001 4.565 3.693 8.258 8.257 8.258z"
                    fill="#f5ebcd"/>
                <path
                    d="m454.194 181.677h-396.388c-4.561 0-8.258 3.697-8.258 8.258v16.516l1.827 2.174v30.859c0 114.02 90.605 186.516 204.625 186.516 114.02 0 205-72.496 205-186.516v-30.817l1.452-2.215v-16.516c0-4.561-3.698-8.259-8.258-8.259z"
                    fill="#faf5dc"/>
                <path
                    d="m256 445.935c114.02 0 206.452-92.431 206.452-206.452v-33.032h-412.904v33.032c0 114.021 92.432 206.452 206.452 206.452zm0-41.29c-88.304 0-163.804-46.544-193.246-111.785 38.546 42.085 110.703 70.495 193.246 70.495s154.7-28.41 193.246-70.494c-29.442 65.24-104.942 111.784-193.246 111.784zm200.75-181.677c-20.67 30.243-96.164 57.806-200.75 57.806s-180.08-27.563-200.75-57.806zm-200.75 74.322c91.652 0 168.687-21.455 203.318-52.966-16.924 58.158-101.621 102.515-203.318 102.515s-186.394-44.357-203.318-102.514c34.631 31.511 111.666 52.965 203.318 52.965z"
                    fill="#f5ebcd"/>
            </g>
        </svg>
    );
}

export default FaceMask;
