import React from 'react';

function Suit() {
    return (
        <svg id="Capa_1" enable-background="new 0 0 512.002 512.002" height="30px" viewBox="0 0 512.002 512.002"
             width="30px" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="m404.011 344.512-42.117-17.549-14.56.581-2.409-8.468c-5.202-3.508-9.469-8.29-11.951-14.087-5.509-12.871-2.025-26.181 6.714-35.347 19.77-20.737 31.926-48.791 31.926-79.704v-24.775l-6.363-15.411 6.363-17.621v-13.251c0-58.556-42.318-109.498-99.097-117.768l-33.032.061c-11.663 1.668-22.765 5.077-33.034 9.956l-47.451 59.874v118.936c0 30.611-.93 50.389 18.5 71.064 3.922 4.173 11.99 11.809 13.5 17.333 4.444 16.257-6.662 41.888-21.333 48l-19.561.628-42.116 17.549c-15.387 6.411-25.409 21.445-25.409 38.114v112.86c0 9.122 7.395 16.516 16.516 16.516h140.387l17.266-9.75 15.766 9.75h140.387c9.122 0 16.516-7.395 16.516-16.516v-112.86c.001-16.67-10.021-31.704-25.408-38.115z"
                    fill="#eceaec"/>
                <path
                    d="m206.453 210.582v-199.451c-39.033 18.566-66.065 58.383-66.065 104.483v16.516l7.863 17.371-7.863 15.661v24.775c0 30.596 11.972 58.337 31.377 79.016l-.028.078c3.922 4.173 7.243 8.996 8.754 14.521 3.864 14.135-2.001 28.189-13.241 35.635l-4.845 8.338c27.017 17.375 59.088 27.573 93.596 27.573v-49.548c.07 0 .137-.01.207-.01-30.047-20.88-49.755-55.597-49.755-94.958z"
                    fill="#dad8db"/>
                <path
                    d="m165.162 396.389c-9.121 0-16.516 7.394-16.516 16.516v24.774h33.032v-24.774c0-9.122-7.394-16.516-16.516-16.516z"
                    fill="#c8c5c9"/>
                <path d="m305.549 99.099h-99.097l-17.452 44.237 149.581-11.204c.001-18.244-14.788-33.033-33.032-33.033z"
                      fill="#ffe09f"/>
                <path d="m330.323 132.131h41.29v33.032h-41.29z" fill="#c8c5c9"/>
                <path d="m140.388 132.131h41.29v33.032h-41.29z" fill="#b6b1b7"/>
                <path
                    d="m194.001 165.163v24.774c0 32.53-1.771 60.667 25.565 74.129 10.99 5.412 23.357 8.452 36.435 8.452 45.608 0 82.581-36.973 82.581-82.581v-24.774z"
                    fill="#faf5dc"/>
                <path
                    d="m173.42 165.163v24.774c0 32.523 18.819 60.664 46.146 74.129-8.358-15.973-13.113-34.201-13.113-53.484v-45.419z"
                    fill="#f5ebcd"/>
                <path
                    d="m132.13 478.97h66.065c4.561 0 8.258-3.697 8.258-8.258v-33.032c0-4.561-3.697-8.258-8.258-8.258h-66.065c-4.561 0-8.258 3.697-8.258 8.258v33.032c0 4.56 3.697 8.258 8.258 8.258z"
                    fill="#c3ddff"/>
                <path
                    d="m338.582 165.163c0 13.682-11.092 24.774-24.774 24.774h-33.032c-4.561 0-8.258-3.697-8.258-8.258 0-9.122-7.395-16.516-16.516-16.516-9.122 0-16.516 7.395-16.516 16.516 0 4.561-3.697 8.258-8.258 8.258h-24.774c-13.682 0-24.774-11.092-24.774-24.774l24.774-33.032h132.129v33.032z"
                    fill="#c3ddff"/>
                <path
                    d="m256.001 66.066c9.121 0 16.516-7.395 16.516-16.516v-48.439c-3.59-.524-7.229-.804-10.927-.978-7.548-.355-14.919.003-22.105 1.033v48.384c0 9.122 7.394 16.516 16.516 16.516z"
                    fill="#a4ccff"/>
                <path d="m173.42 132.131 33.032 7.371v-40.403c-18.243 0-33.032 14.788-33.032 33.032z" fill="#f6d190"/>
                <path d="m206.453 189.937h-8.258c-13.682 0-24.774-11.092-24.774-24.774v-33.032h33.032z" fill="#a4ccff"/>
                <path
                    d="m351.692 322.711c-2.368-.987-4.65-2.209-6.767-3.636-25.31 17.503-55.891 27.765-88.924 27.765v16.516c39.887 0 76.562-13.673 105.894-36.394z"
                    fill="#c3ddff"/>
                <g fill="#a4ccff">
                    <path
                        d="m160.31 322.711c2.368-.987 4.823-2.098 6.94-3.524 25.31 17.503 55.718 27.653 88.751 27.653v16.516c-39.887 0-76.563-13.673-105.894-36.394z"/>
                    <path
                        d="m256.001 305.55c-9.121 0-16.516 7.394-16.516 16.516v189.936h33.032v-189.936c0-9.122-7.395-16.516-16.516-16.516z"/>
                </g>
            </g>
        </svg>
    );
}

export default Suit;
