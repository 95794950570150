import React from 'react';

function HandSanitizer() {
    return (
        <svg id="Capa_1" enable-background="new 0 0 512 512" height="30px" viewBox="0 0 512 512" width="30px"
             xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="m235.355 24.774h33.032v49.548h-33.032z" fill="#a4ccff"/>
                <path
                    d="m351.447 163.797-66.543-39.926-33.032-28.903-33.032 28.903-66.065 39.639-8.048 28.612v287.604c0 13.682 11.092 24.774 24.774 24.774h173.21l23.365-9.017c.913-2.583 1.409-5.362 1.409-8.257v-295.104c-.001-11.603-6.089-22.355-16.038-28.325z"
                    fill="#eceaec"/>
                <path
                    d="m293.161 322.065h-21.354l15.099-15.099c3.225-3.225 3.225-8.454 0-11.679s-8.454-3.225-11.678 0l-15.099 15.099v-21.353c0-4.561-3.697-8.258-8.258-8.258s-8.258 3.697-8.258 8.258v33.032h-33.032c-4.561 0-8.258 3.697-8.258 8.258 0 4.56 3.697 8.258 8.258 8.258h21.354l-15.099 15.099c-3.225 3.225-3.225 8.454 0 11.678 3.225 3.225 8.454 3.225 11.678 0l15.099-15.099v21.354c0 4.56 3.697 8.258 8.258 8.258s8.258-3.698 8.258-8.258v-33.032h33.032c4.561 0 8.258-3.698 8.258-8.258 0-4.561-3.697-8.258-8.258-8.258z"
                    fill="#ffc269"/>
                <path
                    d="m284.903 107.355-57.806 11.645v-44.677c0-4.561 3.697-8.258 8.258-8.258h41.29c4.561 0 8.258 3.697 8.258 8.258z"
                    fill="#c3ddff"/>
                <path
                    d="m357.486 22.424c-.702-2.07-1.603-4.048-2.681-5.911l-119.45-16.513h-8.259c-4.561 0-8.258 3.697-8.258 8.258v16.516c0 4.561 3.697 8.258 8.258 8.258h122.444c5.518 0 9.716-5.383 7.946-10.608z"
                    fill="#c3ddff"/>
                <path
                    d="m375.742 99.097c0 10.141-9.14 18.148-19.632 16.231-7.917-1.446-13.652-9.019-13.386-17.062.399-12.077 9.336-24.005 13.888-29.323 1.399-1.635 3.89-1.567 5.287.069 4.675 5.474 13.843 17.78 13.843 30.085z"
                    fill="#dbeaff"/>
                <circle cx="251.871" cy="330.323" fill="#e5646e" r="33.032"/>
                <path
                    d="m152.774 454.194v-290.684l-.479.287c-9.949 5.97-16.037 16.722-16.037 28.325v295.104c0 13.682 11.092 24.774 24.774 24.774h181.678c10.771 0 19.956-6.916 23.368-16.516h-172.013c-22.805 0-41.291-18.486-41.291-41.29z"
                    fill="#dad8db"/>
                <path
                    d="m235.355 90.839v-24.774h-8.258c-4.561 0-8.258 3.697-8.258 8.258v49.548h66.065v-16.516h-33.032c-9.123 0-16.517-7.395-16.517-16.516z"
                    fill="#a4ccff"/>
                <path
                    d="m354.8 16.516c-5.718-9.849-16.398-16.516-28.606-16.516h-90.839c0 9.122 7.395 16.516 16.516 16.516z"
                    fill="#dbeaff"/>
                <path
                    d="m251.871 239.484c-50.089 0-90.839 40.75-90.839 90.839s40.75 90.839 90.839 90.839 90.839-40.75 90.839-90.839-40.75-90.839-90.839-90.839zm74.323 90.839c0 17.522-6.129 33.617-16.308 46.337l-104.352-104.352c12.72-10.179 28.815-16.308 46.337-16.308 40.984 0 74.323 33.339 74.323 74.323zm-148.646 0c0-17.522 6.129-33.617 16.308-46.337l104.351 104.351c-12.719 10.179-28.815 16.308-46.337 16.308-40.983 0-74.322-33.339-74.322-74.322z"
                    fill="#ff8086"/>
            </g>
        </svg>
    );
}

export default HandSanitizer;
